/* .about-championship ol {
  list-style-type: disc;
}

.about-championship li {
  margin: 8px 0px;
}


.about-championship ul {
  margin-left: 24px;
}

.about-championship ul li {
  margin: 4px 0px !important;
  list-style-type:circle;
} */

.mob-league-container{
  padding-top: 70px;
  padding-bottom: 80px;
}

.mob-about-championship li {
  margin: 8px 10px;
  /* color: #b3b3b3; */
  color: rgb(27, 31, 115);
  font-size: 0.8rem;
  font-weight: 500;
}

.championshipTrailer {
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  background: #e2e8f0;
}

.championship-tabs{
  position: -webkit-sticky;
  position: sticky;
  top: 69px;
  margin-bottom: 20px;
  padding: 5px 20px;
  z-index:999;
  transition: 0.5s all;
  justify-content: center;
}

.championship-tabs.inverted{
  /* background: rgb(27, 31, 115) !important; */
  background: #ffffff;
  transition: 0.5s all;
  /* box-shadow: 0px 1px 5px #080b5e99; */
  box-shadow: 0px 3px 30px #080b5e11;
}

.championship-tabs-item{
  margin: 0px 15px;
  /* width: calc(100vw / 5); */
  text-align: center;
  text-transform: uppercase;
}

.championship-tabs-item.active{
  -webkit-box-shadow:0px 3px 0px #ff1e50 !important;
 -moz-box-shadow:0px 3px 0px #ff1e50 !important;
 box-shadow:0px 3px 0px #ff1e50 !important;
}

.pts_table td {
  border: none !important;
  color: rgb(27, 31, 115);
  vertical-align: inherit !important;
  text-align: center !important;
}

.pts_table th{
  border: none !important;
  padding: 14px 0px !important;
  background: #cbd5e1;
}

.tbl_header {
  background: #ffffff;
  text-align: center !important;
}


.tbl_rows:nth-child(even) {

  background: #e2e8f0 !important;
}

.tbl_rows:nth-child(odd) {
  background: #ffffff;
  border-radius: 4px;
}


.ballTimeline {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 10px 0px 25px 0px;
  background-color: #ffffff;
  padding: 7px 8px;
  border-radius: 12px;
}
::-webkit-scrollbar {
  display: none;
}

.ball_container {
  margin: 0px 8px;
}

.ball{
  color: #ffffff;
  font-weight: 700;
  font-size: 0.7rem;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b1f72;
  border-radius: 50%;
  /* padding: 0px 15px; */
}
.over_seperator {
  color: #fff;
  font-weight: 700;
  line-height: 32px;
  margin: 0px 8px;
}

.winner-view{
  position: absolute;
  top: -12px;
  width: 50%;
  left: calc(50% - 25%);
  text-align: center;
  border-radius: 16px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 990px){
  .mob-league-container{
    width: 50%;
    margin-left: calc(25% + 45px);
  }

  .mob-league-container .team-card{
    min-height: 190px;
  }
}



.gallery-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

.gallery-video-item, .gallery-loader-item{
  margin-bottom: 20px;
  display: inline-flex;
  /* flex-basis: 48%; */
  flex-basis: 31%;
  border-radius: 6px;
  position: relative;
  margin-right: 15px;
}

.gallery-loader-item{
  background: #eaf0f7;
  height: 200px;
  align-items: flex-end;
}

.gallery-video-item img.gallery-video-banner{
  object-fit: none;
  /* height: 220px; */
  height: 200px;
  width: 100%;
  border-radius: 6px;
}

.gallery-loader-meta{
  padding: 20px;
}

.gallery-loader-strip-head{
  height: 20px;
  background: #dee5ec;
  width: 180px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.gallery-loader-playback{
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #dee5ec;
  margin-bottom: 10px;
}

.gallery-video-backdrop{
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(42, 41, 47, 0.3);
  top: 0px;
  left: 0px;
  z-index: 1;
  border-radius: 6px;
}

.gallery-title-block{
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0,0,0,0) 40%, rgba(0,0,0, .8));
  display: flex;
  align-items: flex-end;
  padding: 20px;
  border-radius: 6px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
  transition: 0.5s all;
}

.gallery-title-block:hover + .gallery-title-block > img{
  transform: scale(1.2, 1.2);
  transition: 0.5s all;
}

.gallery-video-title{
  color: #fff;
  /* font-size: 1.1rem; */
  font-size: 0.95rem;
  font-weight: 700;
}

.gallery-video-subtitle{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 0.75rem;
}

.gallery-title-block img{
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
}

.gallery-load-more{
  background: #fff;
  border-radius: 30px;
  padding: 10px 30px;
  border: 1px solid rgba(0,0,0,0.08);
  margin: 15px auto 40px auto;
  cursor: pointer;
}

.gallery-load-more:hover{
  background: #fcfcfc;
}



.teams-block {
  /* background-image: linear,left bottom,right top,color-stop(1%,#ff1d50),color-stop(52%,#ff1d50); */
  /* background-image: -webkit-linear-gradient(45deg,#ff1d50
 1%,#1b1f73 52%,#ff1d50
 52%,#1b1f73 100%); */
 background-image: linear-gradient(#dae8ff, #cacaca );
 /* background-image: url('https://viratkohli.foundation/wp-content/themes/onum/images/bg-page-header.jpg'); */
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  padding: 10px 0px;
}
.mob-nav-bar{
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 70px;
  /* background: rgb(27, 31, 115) !important; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  transition: 0.5s all;
}

.mob-nav-bar.inverted{
  /* background: rgb(27, 31, 115) !important; */
  background: #ffffff !important;
  transition: 0.5s all;
  box-shadow: 0px 7px 30px #080b5e11;
  z-index: 999;
}

.back-arrow{
  transform: rotate(180deg);
  position: absolute;
  top: 15px;
  left: 15px;
}

@media (min-width: 990px){
  .mob-nav-bar{
    width: calc(100% - 90px);
    margin-left: 90px;
  }
}
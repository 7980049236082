.dashboard-container{
    padding-bottom: 100px;
    padding-top: 60px;
  }

  .trial-hero{
    /* padding: 1.55rem !important; */
    margin-top: -3.25rem;
    /* background: linear-gradient(
      136deg,#1b1f72, #232882); */
      background-color: #ffffff;
    margin: 30px 0 0 0;
    border-radius: 10px;
    height: auto;
    width: calc(100vw - 40px);
    margin-left: 20px;
    box-shadow: 0px 7px 30px #080b5e22;
  }

  .trial-hero .column{
    padding: 0px;
  }

  .trial-hero-footer{
    /* background: #1c1a69; */
    /* background: #e2e8f0; */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #e2e8f0;
    padding: 10px 0px;
  }

  .trial-hero-footer-payment{
    padding: 10px 0px;
  }

  .league-hero-theme{
    background: linear-gradient(136deg,#1b1f72, #232882);
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    align-items: center;
    display: flex;
    padding: 30px 15px;
  }

  .league-context h1{
    font-size: 1.2rem !important;
    margin-bottom: 20px;
  }

  .league-hero-theme.secondary{
    background: #ff1e50 !important;
  }

  .dashboard-container-loading{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
  }

  .payment-status-card {
    /* background-color: #272da5;
    border-radius: 6px;
    text-align: center;
    padding: 12px;
    margin-bottom: 24px; */
    padding: 20px 0;
    /* border-top: 1px solid #191782; */
    border-top: 1px solid #e2e8f0;
  }

  /* .payment-status-card:last-child{
  } */

  .payment-status-card p{
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: 0.8rem !important;
    font-weight: 600;
    padding: 0px 20px;
  }

  .payment-status-text {
    font-weight: 700; 
    font-size: 12px; 
    color: #fff;
  }

  .dashboard-labels{
    margin: 35px 0 10px 22px;
    letter-spacing: 1.2px;
  }

  .button[disabled], fieldset[disabled] .button{
    border:transparent;
  }
  
  .trial-info-card div{
    border-top: 1px solid #e2e8f099;
    padding: 10px 0px;
  }

  .trial-info-card div small{
    text-transform: uppercase;
    letter-spacing: 0.8px;
    display: block;
  }


  @media (min-width: 990px){
    .dashboard-container{
      width: 50%;
      margin-left: calc(25% + 45px);
    }

    .trial-hero{
      width: calc(100%);
    }
  }












  /*  */
.androidBanner{
  background-image: url('../../images/android.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  margin-top: 40px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}

  /*  */
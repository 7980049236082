
  .tab-bar{
  position: fixed;
  bottom: 15px;
  left:10px;
  width: calc(100% - 20px);
  height: 60px;
  /* background: rgb(27, 31, 115) !important; */
  background: #ffffff!important;
  box-shadow: 0px 6px 30px #080b5e33;
  display: flex;
  flex-direction: row;
  z-index: 999;
  border-radius: 30px;
  /* margin-bottom: constant(safe-area-inset-bottom); */
}

.tab-bar-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: calc(100vw / 5);
  flex: 1;
  cursor: pointer;
}

.tab-bar-item.active{
  /* background-color: #ff1e5099; */
  background-color: #1b1f7311;
}

.tab-bar-item:first-child.active{
  /* background-color: #ff1e5099; */
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.tab-bar-item:last-child.active{
  /* background-color: #ff1e5099; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.tab-bar-item:focus{
  background: rgb(27, 31, 115, 0.9) !important;
}

.tab-bar img{
  width: 20px;
  height: 20px;
}

.tab-bar .tab-bar-item small{
  display: inline-block;
  margin-top: 3px;
  font-size: 0.6rem !important;
  font-weight: 500;
}

.mob-side-menu{
  width: 300px;
  height: 100%;
  /* background: rgb(34 37 137) !important; */
  background: #f1f5f9 !important;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.5s all;
  /* box-shadow: 0px 0px 1px 1px #251f78; */
  box-shadow: 4px 0px 8px 1px #7979f8;
}

.mob-side-menu.active{
  right: 0px;
  transition: 0.5s all;
}

.mob-sidebar-item{
  padding: 10px 20px;
  /* background:  #191e7c; */
  /* border-bottom: 1px solid #15197455; */
  border-bottom: 1px solid #cbd5e166;
  cursor: pointer;
}

.mob-sidebar-item:last-child{
  border-bottom: none;
}

.mob-sidebar-items{
  flex-grow: 1;
}

.mob-version{
  padding-bottom: 0px;
}

@media (min-width: 990px){
  .tab-bar{
    width: 90px;
    height: 100%;
    flex-direction: column;
    bottom: 0px;
    left:0px;
    border-radius: 0px;
  }

  .tab-bar-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 90px;
    flex: 0 0;
    padding: 30px 0px;
  }

  .tab-bar-item img, .tab-bar-item i{
    width: 30px;
    height: 30px;
    color:rgb(27, 31, 115)
  }

  img.tab-bar-logo{
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  
}

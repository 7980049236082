.tournament-container{
  padding-top: 70px;
  padding-bottom: 80px;
}

.tournament-container .column{
  width: 100%;
  flex-basis: 100%;
}


  @media (min-width: 990px){
    .tournament-container{
      width: 50%;
      margin-left: calc(25% + 45px);
    }

    .tournament-container .column{
      width: 50%;
      flex-basis: 50%;
    }
  }
.mob-profile-container{
  padding-top: 70px;
  padding-bottom: 80px;
}

.profile-avatar{
  border: 4px solid #ffffff;
}

.mob-profile-container input{
  padding: 0px;
  height: auto;
  background: #ffffff !important;
  border: 1px solid transparent !important;
  color: rgb(27, 31, 115) !important
}

.mob-profile-container .field{
  /* background: rgb(27, 31, 115, .5) !important; */
  background: #ffffff;
  box-shadow: 0px 7px 30px #080b5e22;
}

.mob-profile-avatar{
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #f5f5f5;
}

@media (min-width: 990px){
  .mob-profile-container{
    width: 70%;
    margin-left: calc(15% + 45px);
  }

  .mob-profile-container .columns {
    /* flex-direction: column; */
  }
}

.all-matches-wrapper{
  padding: 0px 20px;
}

.match-item{
  /* background: rgb(27, 31, 115) !important; */
  background: #ffffff !important;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0px 7px 30px #080b5e22;
}

.match-item-box:first-child, .match-item-box:last-child {
  width:40%;
}

/* .match-item-box:first-child{
  align-items: flex-start !important;
}

.match-item-box:last-child {
  align-items: flex-end !important;
} */

.match-details-tab li{
  width: 50%;
}
.match-details-tab.tabs.is-toggle a{
  border-color: #989de0;
  font-size: 0.8rem !important;
}

.match-details-tab.tabs.is-toggle li.is-active a{
  color: #ffffff !important;
  z-index: 0;
}

.match-team-logo{
  width: 50px;
  height:50px;
}

.match-toss-details{
  /* background:  rgb(21 24 74 / 90%) !important; */
  background: #ffffff !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid #e2e8f099;
}

.is-size-8{
  font-size: 0.65rem !important;
}

.match-toss-details .match-winner-text{
  font-size: 0.50rem!important;
}

.match-details-wrapper{
  padding: 0px 20px;
}

.match-top-background{
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh / 6);
  background: rgba(19,22,74) !important;
  width: 100%;
  z-index: 1;
}

.match-details-meta{
  margin-top: 50px;
  position: fixed;
  z-index: 99;
  width: calc(100vw - 40px);
}

.match-inning-wrapper{
  padding-top: calc(100vh / 6 + 70px);
}

.match-inning-score-row.even{
  /* background: rgb(27, 31, 115) !important; */
  background: #e2e8f0 !important;
}

.match-inning-score-row, .match-inning-score-header {
  padding: 5px 10px;
  border-radius: 4px;
}

.inning-card:last-child{
  margin-top: 50px !important;
}

.match-inning-score-header-item.w-50{
  width: 50% !important;
}

.match-inning-score-header-item.w-125{
  width: 12.5% !important;
}

.match-inning-score-header-item.w-15{
  width: 15% !important;
  text-align: center;
}

.match-inning-score-header-item.w-10{
  width: 10% !important;
  text-align: center;
}

.back-to-list{
  position: fixed;
  z-index: 999;
  top: 12px;
  left: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.back-to-list img{
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
}

@media (min-width: 990px){
  .match-details-wrapper, .match-details-meta{
    width: 50%;
    margin-left: calc(25%);
  }

  .match-details-meta{
    margin-left: -20px;
  }

  .match-inning-wrapper {
    padding-top: calc(100vh / 6 + 85px);
  }

  
}
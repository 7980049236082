.trial-container{
  padding-top: 70px;
  padding-bottom: 80px;
}

.mob-trial-info li {
  margin: 8px 10px;
  /* color: #b3b3b3; */
  color: rgb(27, 31, 115);
  font-size: 0.8rem;
  font-weight: 500;
}

.mob-top-players{
  padding-bottom: 80px;
}

.top-trial-players h1{
  font-size: 1.4rem !important;
}

.mob-top-players .top-trial-players{
  margin-top: 0px;
}

.score-box{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.top-trial-players .media{
  /* background: #222295; */
  background: #ffffff;
  box-shadow: 0px 7px 30px #080b5e22;
}

@media (min-width: 990px){
.mob-top-players{
  /* width: 50%; */
  margin-left: 90px;
}
}

@import url('margins.css');
body{
  margin: 0;
  padding: 0;
  color: #1A202C;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Work Sans', sans-serif; */
}

.sf{
  /* font-family: 'Muli', sans-serif !important; */
}

.is-relative{
  position: relative !important;
}

.pc{
  color:#343434;
}

.text-muted{
  color: #888;
}

button{
  outline: none;
}

.input, .textarea, .input:focus, .textarea:focus, .input:active, .textarea:active, textarea, textarea:focus{
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #e9e9e9;
  outline:none;
}

.columns{
  margin: 0 !important;
}

.fcb_b{
  color:#3753A4;
}

.fcb_y{
  color: #FBD74C;
}

.fcb_p{
  color: #EF7CB1;
}

.flex{
  display: flex !important;
}

.text-white{
  color: #fff;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.text-justify{
  text-align: justify;
}

.jc{
  justify-content: center;
}

.ai{
  align-items: center;
}

.text-center{
  text-align: center;
}

.center-align{
  justify-content: center;
  align-items: center;
}

.f-d-column{
  flex-direction: column;
}

.f-d-row{
  flex-direction: row;
}

.progressBar{
  width: 100%;
  height: 12px;
  background-color: #dbdbdb;
  border-radius: 10px;
  outline: none;
  border: none;
}

.progressValue{
  height: 12px;
  border-radius: 10px;
  transition: 0.3s all linear;
  animation: progress 3s ease-in-out forwards;
  -webkit-animation: progress 3s ease-in-out forwards;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.table_profile td{
  border: none !important;
}

.table_profile .value{
  display: flex;
  justify-content: center;
}

.selectedSpeciality{
  border: 1px solid #cacaca;
  background-color: #f5f5f5 !important;
}


.footer-social li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #999;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}

/*.. Zooter.....*/
.zooter_link{
  color: #3753A4;
}

.zooter_link:hover{
  color: #cacaca;
}

/*........Badge.........*/
.badge{
  width: 8px;
  height: 8px;
  border-radius: 13px;
  background-color: #ef7cb1;
  position: absolute;
  right: 16px;
  z-index: 199;
  align-items: center;
  justify-content: center;
}


.home-block{
  margin: 50px 0;
}

.convinced-block{
  background: #f7f7fa;
  padding: 50px !important;
  margin: 80px 0;
}

.nav-options{
  display: none;
}

.profile-wrapper .is-3{
  width: 50% !important;
  margin: 0 auto !important;
  padding: 40px;
}

.profile-wrapper{
  margin-top: 40px !important;
}

.collaborations img{
  object-fit: cover;
}




/* Tournament */


/* Tournament End */


@media (max-width: 768px){
  .mobile-container{
    text-align: center
  }

  .scroller{
    flex-wrap: wrap;
  }

  .scroller > div{
    margin-bottom: 10px;
  }

  .home-block{
    margin: 20px 0;
  }

  .is-size-1.content-title{
    font-size: 2.2rem !important;
    text-align: center;
  }

  .convinced-block{
    padding: 20px !important;
  }

  .has-text-weight-light.content-subtitle{
    font-weight: 500!important;
    font-size: 1.2rem !important;
    text-align: center;
  }

  .profile-wrapper{
    flex-direction: column;
    margin-top: 0px !important;
  }

  .profile-wrapper > .column{
    width: 100% !important;
  }

  .navbar-menu {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .navbar-brand, .navbar-tabs{
    justify-content: center;
  }


  .nav-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    background: #1c216f;
    flex-wrap: wrap;
  }

  .nav-options-item{
    margin: 4px 15px;
  }

  .nav-options-item a{
    color: #fff;
    font-size: 0.9rem;
  }

  .profile-wrapper .is-3{
    width: 100% !important;
    margin: 0 auto !important;
    border: none;
    padding: 20px;
  }

  .about-wrapper .is-12, .collaborations .is-3, .mentor-wrapper .is-4, .mentor-wrapper .is-8{
    display: block !important;
    width: 100% !important;
  }

  .collaborations, .video-wrapper, .mentor-wrapper{
    padding: 25px;
  }

  .gallery-video-item, .gallery-loader-item{
    flex-basis: 100% !important;
  }

  .mentor-wrapper{
    display: block !important;
  }

}

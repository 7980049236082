*{
    margin: 0;
    padding: 0;
    /* font-family: 'Muli', sans-serif; */
    font-family: 'Poppins', sans-serif;
  }
  .scroller::-webkit-scrollbar {
    display: none;
  }
  
  body, html{
    /* background-color: #272da5; */
    background-color: #f1f5f9!important;
  }
  
  .pc{
    color: rgb(27, 31, 115) !important;
  }
  .pcb{
    background: rgb(27, 31, 115) !important;
  }
  
  .sc{
    color: #ff1e50 !important;
  }
  
  .scb{
    background: #ff1e50 !important;
  }
  
  /* .left-rounded{
    border-top-left-radius: 400px;
    border-bottom-left-radius: 400px;
  }
  
  .right-rounded{
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
  } */
  
  /* .home-block img {
    height: 450px;
    opacity: 0.8;
  } */
  
  .button.all-sports-btn{
    transition: 0.8s all;
    background: #ff1e50 !important;
    color: #fff !important;
    border: 2px solid #ff1e50 !important;
    font-weight: 500;
  }
  
  .button.all-sports-btn:hover{
    /* background: transparent !important; */
    color: #fff !important;
    padding-right:1.8em;
    padding-left:1.8em;
    transition: 0.8s all;
    border-width: 2px !important;
  }
  
  /**common**/
  .text-center{
    text-align: center;
  }
  
  .blink-tag{
    animation: blinker 1s linear infinite;
    height: 1.7em !important;
    font-size: 0.7rem !important;
    font-weight: 700 !important;
  }
  
  @keyframes blinker {  
    50% { opacity: 0.3; }
  }
  
  /**common**/
  
  /***navbar***/
  
  .navbar{
    background: #ffffff;
    padding: 0px 10%;
  }
  
  .bg-primary-color {
    background: #232882;
  }
  .navbar-item.has-dropdown:hover{
    background: rgba(0,0,0,0.1);
  }
  
  .navbar-item, .navbar-link, .navbar-item:hover,
  .navbar-link:hover, a.navbar-item:hover{
    /* color: #ff1e50; */
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  
  .navbar > .container{
    padding: 0 100px;
  }
  
  a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover,
  a.navbar-item.is-active, .navbar-link:focus,
  .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active{
    background: rgba(0,0,0,0.1);
  }
  
  .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover
  .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link{
    background: rgba(0,0,0,0.1);
  }
  
  .navbar-item.has-dropdown.is-hoverable:hover .navbar-item{
    color: #001036;
  }
  
  /***navbar***/
  
  
  /***home***/
  .hero-title{
    font-weight: 900;
    color: #fff;
    font-size: 3.5rem !important;
  }
  
  .hero-subtitle{
    font-weight: 600;
    color: #ff1e50;
    position: relative;
  }
  
  .hero-subtitle:after{
    content: "";
    position: absolute;
    width: 100px;
    height: 4px;
    border-radius: 60px;
    background: #fff;
    bottom: -30px;
    left: 50%;
    margin-left: -50px;
  }
  
  .hero-sports-title{
    color: #ff1e50;
    margin-top: 10px;
    font-size: 0.9rem !important;
  }
  
  .top-bar-menu .level-item{
    color: #fff;
    cursor: pointer;
  }
  
  .top-bar-menu .level-item:hover{
    font-weight: 700;
    color: #ff1e50;
  }
  
  .trial-hero{
    /* background: url(https://viratkohli.foundation/wp-content/themes/onum/images/bg-page-header.jpg); */
    height: 70vh;
  }
  
  .trial-info-block{
    background-color: #3796D10F;
  }
  
  .about-trials .column .about-trial-blocks{
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 20px;
    min-height: 310px;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .featured-video{
    width: 60%;
    height: 430px;
    border-radius: 8px;
    margin-left: 20%;
  }
  
  .featured-video-wrapper{
    padding: 60px 0px;
  }
  
  .top-trial-players{
    margin-top: 30px;
  }
  
  .top-trial-players .media {
    background: #f5f5ff;
    border-radius: 4px;
  }
  
  @-moz-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:0.8;
    }
  } 
  
  @-webkit-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:0.8;
    }
  }
  /* IE */
  @-ms-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:0.8;
    }
  } 
  /* Opera and prob css3 final iteration */
  @keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:0.8;
    }
  } 
  
  .shauryaLoaderWrapper{
    height: 50vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .shauryaLoader {
    -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
    -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
    -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
    animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
    width: 100px;
    border-radius: 10px;
    height: 100px;
    border-top: 10px solid #232882;
    opacity: 0.7;
  }
  
  .shaurya-logo{
    width: 100px;
    border-radius: 10px;
    height: 100px;
    opacity: 1;
    margin: 40px 0px;
  }
  
  .my-trial-container{
    background-color: #f1f5f9!important;;
  }
  
  .my-trial-container > div {
    margin: 60px 0px;
  }
  
  .my-trial-info-block{
    background-color: #212d7e;
    padding-bottom: 50px;
    z-index:10;
  }
  
  
  .my-trial-info-block-loader{
    background-color: transparent;
    width: calc(100vw - 200px);
    height: 50vh;
    margin-left: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
  }
  
  .my-trial-info-block-loader img {
    width: 200px;
  }
  
  .score-title{
    font-size: 4.5rem;
    color: #fff;
  }
  
  .per-ball-score-container{
    position: relative;
    top: 0px;
    z-index: 20;
  }
  
  .score-table{
    background: #d93458 !important;
    border-radius: 10px;
  }
  
  .score-table-head{
    background: #cd294d !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .score-table-head div {
    height: 50px;
    display: flex;
    align-items: center;
    width: 25%;
    text-align: center;
    justify-content: center;
  }
  .score-table-body-item{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-bottom: 1px solid #cd294d;
  }
  
  .score-table-body-item:last-child{
    border-bottom: none !important;
  }
  
  .score-table-body-item div {
    text-align: center;
    width: 25%;
    color: #d5d5d5;
  }
  
  .score-table{
    font-size: 12px;
  }
  
  .score-box{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  
  .offering-block{
    margin-top: 60px;
  }
  .league-hero{
    background: linear-gradient(136deg,#f85c0099, #fdb91399);
    width: 100%;
    height: 400px;
    margin-top: 20px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    padding: 0px 50px;
  }
  
  .home-wrapper .league-hero{
    margin-top: 0px;
    border-radius: 0px;
    min-height: calc(100vh - 3.25rem);
  }
  
  .league-sponsors{
    margin-top: 60px;
    padding: 50px 0;
    background: #f4f4f4;
  }
  
  .sponsor-wrapper img{
    width: 120px;
    height: 120px;
  }
  
  .team-card{
    /* background: linear-gradient(136deg,#0d2068,#1e2795); */
    background: #ffffff;
    border-radius: 6px;
    padding: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 7px 30px #080b5e22;
  }
  
  .selected-players{
    z-index: 1000000;
  }
  
  .selected-players .modal-content{
    /* background: rgb(27, 31, 115) !important; */
    /* background: #ffffff; */
    border-radius: 6px;
    position: relative;
    background: #e0e0e1;
  }
  
  .selected-players .modal-content .modal-close {
    position: absolute;
  }
  
  .selected-players .table{
    /* background: rgb(27, 31, 115) !important; */
    width:100%;
  }
  
  .selected-players .table{
    /* background: rgb(27, 31, 115) !important; */
    width:100%;
  }
  
  .selected-players .table tbody{
    background: #f3f3fa !important;
  }
  
  .selected-players .table tr{
    /* background: rgb(11 17 131) !important; */
    /* background: rgb(27, 31, 115) !important; */
    /* background: #232882; */
    color: #ffffff;
  }
  
  .selected-players .table tr a{
    color: #ffffff;
  }
  
  .selected-players .table tr td{
    vertical-align:middle
  }
  
  .shaurya-logo{
    border-radius: 6px;
  }
  
  .hl-container{
    padding: 50px !important;
  }
  
  .hl-video-column{
    height: 350px;
    background-color: #f3f3f3;
    width:200px;
    border-radius: 6px;
    margin-right: 18px;
    position: relative;
  }
  
  .hl-video-banner{
    width: 200px;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
  }
  
  .hl-video-row{
    margin-top: 25px !important;
  }
  
  .hl-video-row p small{
    margin: 0px 0px 8px 0px;
    display: block;
  }
  
  .hl-video-row .column{
    padding: 0.75rem 0px;
    flex-basis: 50%;
  }
  
  .hl-video-row .columns{
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .hl-video-meta{
    z-index: 2;
    bottom: 0;
    background: linear-gradient(transparent 40%,rgba(0,0,0,.8));
    display: flex;
    align-items: flex-end;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    cursor: pointer;
    transition: all .5s;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 6px;
  }
  
  .hl-video-backdrop{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    background: rgba(42,41,47,.3);
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .hl-video-column-wrapper{
    margin-top: 10px;
  }
  
  /***home***/
  
  
  
  /**temp classes*/
  
  .coming-soon{
    font-weight: 500;
    font-size: 0.66rem;
    color: #ccc;
  }
  
  @media (max-width: 768px){
    .hero-title{
      font-weight: 900;
      color: #fff;
      font-size: 2.5rem !important;
      line-height: 3rem;
    }
  
    .hero-subtitle{
      font-size: 1.2rem !important;
      margin-top: 20px;
      padding: 0px 20px;
    }
  
    .navbar {
      background: #ffffff;
      padding: 0px 16px;
    }
  
    .about-trials .column .about-trial-blocks{
      min-height: auto !important;
    }
  
    .about-trials{
      padding: 1.25rem !important;
    }
  
    .featured-video{
      width: 100%;
      height: 430px;
      border-radius: 0px;
      margin-left: 0%;
    }
  
    .my-trial-container > div {
      margin: 10px 0px;
    }
  
    .my-trial-info-block-loader{
      width: calc(100vw - 20px);
      height: 50vh;
      margin-left: 10px;
    }
  
    .top-trial-players .is-6{
      width:100%;
      flex-basis: auto;
    }
  
    .league-hero{
      background: linear-gradient(136deg,#f85c0099, #fdb91399);
      width: calc(100%);
      height: auto;
      margin-top: 0px;
      border-radius: 0px;
      align-items: center;
      display: flex;
      padding: 40px 20px;
      flex-direction: column;
      text-align: center;
    }
  
    .league-hero div.league-context{ 
      order: 2; 
      align-items: center !important;
    }
  
    .trophy-area img {
      width: 100px;
      margin-bottom: 0px;
      display: none;
    }
  
    .league-sponsors{
      text-align: center;
    }
  
    .league-teams.columns, .sponsor-wrapper.columns{
      flex-wrap: wrap;
      display: flex !important;
    }
    .sponsor-wrapper .column div:first-child{
      margin-left: 0% !important;
    }
  
    .league-teams .column, .sponsor-wrapper .column{
      flex-basis: 50%;
    }
  
    .team-card{
      min-height: 120px;
    }
  
    .home-wrapper .league-hero{
      /* background: rgb(27, 31, 115); */
      /* background: #ffffff; */
      min-height: calc(100vh - 3.25rem);
    }
  
    .sponsor-wrapper img{
      width: 80px;
      height: 80px;
    }
  
    .modal.is-active{
      padding: 15px;
    }
  
    .hl-title{
      font-size: 1.8rem !important;
    }
  
    .hl-container{
      padding: 20px !important;
    }
  
    .hl-video-column{
      height: 250px;
      background-color: #f3f3f3;
      width:160px;
      border-radius: 6px;
      margin-right: 18px;
      position: relative;
    }
  
    .hl-video-banner{
      width: 160px;
      height: 250px;
      object-fit: cover;
      border-radius: 6px;
    }
  
    .hl-video-row{
      margin-top: 25px !important;
    }
  
    .hl-video-row p small{
      margin: 0px 0px 8px 0px;
      display: block;
    }
  
    .hl-video-row .column{
      padding: 0.75rem 0px;
      flex-basis: 50%;
    }
  
    .hl-video-row .columns{
      overflow-x: auto;
      overflow-y: hidden;
    }
  
    .hl-video-meta{
      z-index: 2;
      bottom: 0;
      background: linear-gradient(transparent 40%,rgba(0,0,0,.8));
      display: flex;
      align-items: flex-end;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      cursor: pointer;
      transition: all .5s;
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }
  
    .hl-video-backdrop{
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 6px;
      background: rgba(42,41,47,.3);
      top: 0;
      left: 0;
      z-index: 1;
    }
  
    .hl-video-column-wrapper{
      margin-top: 10px;
    }
  
    .navbar-menu{
      max-height: 400px !important;
    }
    
  }
  
  @media (min-width: 900px) {
    .home-wrapper .league-hero {
      width: calc(100vw - 100px);
      margin-left: 50px;
      margin-top: 30px;
      border-radius: 8px;
      height: 300px!important;
      min-height: 300px!important;
    }
    
    .home-wrapper .league-hero .shaurya-logo{
      margin: 20px 0px;
    }
  }
  
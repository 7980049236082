.trial-container {
  padding-top: 70px;
  padding-bottom: 80px;
}

.trial-container .column {
  width: 100%;
  flex-basis: 100%;
}

.trial-status{
  position: absolute;
  top: 0px;
  right: 10px;
}

.position-relative{
  position: relative;
}

.trial-stats{
  border-top: 1px solid #e2e8f099;
  padding: 10px 0;
  margin-top: 10px;
}

.trial-container .team-card, .tournament-container .team-card{
  align-items: unset;
  padding-bottom: 0px;
}

@media (min-width: 990px) {
  .trial-container {
    width: 50%;
    margin-left: calc(25% + 45px);
  }

  .trial-container .column {
    width: 50%;
    flex-basis: 50%;
  }
}

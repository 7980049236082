/********************MARGINGS*************************/
/****************************************************/

.mt-0{
  margin-top: 0px !important
}

.mb-0{
  margin-bottom: 0px !important
}

.mb-60{
  margin-bottom: 60px !important;
}

.mt-60{
  margin-top: 60px !important;
}

.no-padding{
  padding: 0px !important
}

/********************TOP*************************/
.mt-1{
  margin-top:5px;
}
.mt-2{
  margin-top:10px;
}
.mt-3{
  margin-top:15px;
}
.mt-4{
  margin-top:20px;
}
.mt-5{
  margin-top:30px;
}

/********************LEFT*************************/

.ml-1{
  margin-left:5px;
}
.ml-2{
  margin-left:10px;
}
.ml-3{
  margin-left:15px;
}
.ml-4{
  margin-left:20px;
}
.ml-5{
  margin-left:30px;
}

/********************BOTTOM*************************/

.mb-1{
  margin-bottom:5px;
}
.mb-2{
  margin-bottom:10px;
}
.mb-3{
  margin-bottom:15px;
}
.mb-4{
  margin-bottom:20px;
}
.mb-5{
  margin-bottom:30px;
}


/********************RIGHT*************************/

.mr-1{
  margin-right:5px;
}
.mr-2{
  margin-right:10px;
}
.mr-3{
  margin-right:15px;
}
.mr-4{
  margin-right:20px;
}
.mr-5{
  margin-right:30px;
}


/********************PADDINGS*************************/
/****************************************************/

/********************TOP*************************/
.pt-1{
  padding-top:5px;
}
.pt-2{
  padding-top:10px;
}
.pt-3{
  padding-top:15px;
}
.pt-4{
  padding-top:20px;
}
.pt-5{
  padding-top:30px;
}

.pt-fixed{
  padding-top:65px;
}

/********************LEFT*************************/

.p-2{
  padding:10px;
}

.p-3{
  padding:15px;
}

.p-4{
  padding: 20px;
}

.p-5{
  padding: 30px;
}

.pl-1{
  padding-left:5px;
}
.pl-2{
  padding-left:10px;
}
.pl-3{
  padding-left:15px;
}
.pl-4{
  padding-left:20px;
}
.pl-5{
  padding-left:30px;
}

/********************BOTTOM*************************/

.pb-1{
  padding-bottom:5px;
}
.pb-2{
  padding-bottom:10px;
}
.pb-3{
  padding-bottom:15px;
}
.pb-4{
  padding-bottom:20px;
}
.pb-5{
  padding-bottom:30px;
}


/********************RIGHT*************************/

.pr-1{
  padding-right:5px;
}
.pr-2{
  padding-right:10px !important;
}
.pr-3{
  padding-right:15px;
}
.pr-4{
  padding-right:20px;
}
.pr-5{
  padding-right:30px;
}

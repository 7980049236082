.mob-container {
  padding-bottom: 100px;
  padding-top: 60px;
}

.transaction-container{
  width: calc(100vw - 30px);
  margin-left: 15px;
}

.transaction-container hr{
  height: 1px;
  margin: 10px 0;
}

@media (min-width: 990px){
  .transaction-container{
    width: 50%;
    margin-left: calc(25% + 45px);
    /* padding-left: 10px; */
  }
}

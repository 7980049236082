.mob-notification-container{
    padding-top: 70px;
    padding-bottom: 80px;
}

.mob-notification-item{
    /* background: rgb(27, 31, 115, .5) !important; */
    background: #ffffff !important;
    box-shadow: 0px 7px 30px #080b5e22;
    padding: 15px 0px;
}

.media.mob-notification-item + .media.mob-notification-item{
    border-top: 1px solid rgb(27, 31, 115, .7) !important;
    margin-top: 0px !important;
    padding-top: 15px !important;
}

.mob-notification-container .tabs.is-toggle a{
    border-color: #646363;
}

.tabs.is-toggle a:hover{
    background: transparent;
}

@media (min-width: 990px){
    .mob-notification-container{
      width: 50%;
      margin-left: calc(25% + 45px);
      padding-left: 10px;
    }
  }
@media (min-width:768px){
  .auth-timeline{
    width: calc(100vw / 6);
    flex-direction: column;
  }

  .auth-timeline-item{
    padding: 0px 12.5px;
    background: #fff;
    height: 50px;
    margin-bottom: 80px;
    border-radius: 50px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04); */
    align-items: center;
    display: flex;
    position:relative;
    flex-direction: row;
  }

  .auth-timeline-item.active{
    background: #f8faff;
  }

  .auth-timeline-item span{
    color:#3753A4;
    font-size: 0.9rem;
  }

  .auth-timeline-item:before{
    content: "";
    height: 130px;
    width: 2px;
    background: #f0f0f0;
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: -1;
  }

  .auth-timeline-icon{
    width:25px;
    height: 25px;
    margin-right: 15px;
  }

  .auth-timeline-item:last-child:before{
    content: none;
  }

  .timeline-wrapper{
    position: absolute !important;
    background: #fff;
    min-height: 100vh;
    top: 0;
    width: calc(25%);
    z-index: 1;
    padding: 80px 40px;
  }

}

.f-pass{
  min-height: 100vh;
  background: #ffffff !important
}

/* .f-pass:after{
  content: '';
  left: 0;
  content: '';
  width: 100%;
  height: 500px;
  background: #f4f4f4;
  position: fixed;
  top: 0;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: 0;
} */

.f-pass input{
  box-shadow: none;
  /* border:1px solid #f0f0f0; */
  border-radius: 30px;
  /* box-shadow: 0 3px 4px #3753a405; */
  background: #f7f7fa;
  padding-left: 20px;
}

.field{
  padding: 15px;
  border-radius: 6px;
  /* background: rgba(55,83,164,0.08); */
}

.auth-graphics{
  width: calc(100vw / 2);
  position: fixed;
  bottom: -75px;
  right: -2px;
  z-index: 10;
}

.basic_info_wrapper{
  padding: 80px 0;
  position: relative;
  z-index: 2;
  z-index: 11;
}

.fcb-info{
  padding: 0 30px;
}

.basic_info_wrapper .basic_info_form{
  border-radius: 6px;
  border: 2px solid #f4f4f4;
  padding: 30px;
  z-index: 5;
  background: #fff;
  /* margin-left: calc(25% + 8.333%); */
  margin: 0 auto;
}

.f-pass .basic_info_wrapper .form_label{
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
  font-size: 0.9rem !important;
  margin-bottom: 15px !important;
}

.applying_options{
  justify-content: space-between;
}

.applying_items{
  width:150px;
  height: 100px;
  border-radius: 6px;
  box-shadow: 0 3px 4px #3753a405;
  background: #fff;
  cursor: pointer;
}

.applying_items:hover{
  border:2px dashed #3753a485;
}

.auth-action-button{
  margin-top: 20px !important;
  width: calc(100vw / 7) !important;
  height: 45px !important;
  background: #ff1e50 !important;
  border-radius: 40px;
  color: #fff;
  font-weight: 500;
}

.auth-action-button:hover{
  color: #fff;
  font-weight: 600;
}

.applying_item_title{
  color: #999;
  text-align: center;
  font-size: 0.7rem !important;
  letter-spacing: 0.4px;
  font-weight: 500;
}

@media (max-width:768px){

  .f-pass{
    padding: 10px;
  }

  .basic_info_wrapper{
    padding:0;
  }

  .basic_info_wrapper .basic_info_form{
    padding: 40px 25px;
    margin-left: 0!important;
    box-shadow: none;
  }

  .auth-section-title{
    font-size: 1.5rem !important;
  }

  .info-labels{
    font-size: 0.9rem;
  }

  .f-pass .basic_info_wrapper .field{
    /* background: rgba(55,83,164,0.13); */
    margin-bottom: 30px;
    padding: 0px;
  }

  select{
    font-size: 1.25rem !important;
    background: #f7f7fa !important;
  }

  .auth-action-button{
    width: calc(100vw / 2) !important;
  }

  .applying_options{
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .applying_items{
    height: calc(100vw / 2 - 60px);
    width: calc(100vw / 2 - 40px);
    padding: 8px;
    align-items: center;
    justify-content: center;
    background: #f7f7fa;
    margin-bottom: 15px;
  }

  .applying_items img{
    width: 48px;
    height: 48px;
  }

  .applying_item_title {
    color: #777;
    font-size: 0.9rem !important;
  }

}
